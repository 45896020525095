/* eslint-disable react/no-array-index-key */
import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from 'react-icons/md';
import { Link } from 'react-router-dom';

import logoRound from '../../images/logo_round.png';
import flag1 from '../../images/flag-1.png';
import flag2 from '../../images/flag-2.png';
import styles from './footer.module.scss';

interface Props {
  siteName: string;
  email: string;
  phone: string;
  address: string;
  addressGoogleMapsUrl: string;
  openingHours: string;
  openingHoursMessage?: string;
  socials?: {
    icon: { type?: string; name: string };
    href: string;
    label: string;
  }[];
}

const Footer = (props: Props): JSX.Element => {
  const {
    email,
    phone,
    address,
    addressGoogleMapsUrl,
    openingHours,
    openingHoursMessage,
    siteName,
    socials,
  } = props;
  const year = new Date().getFullYear();

  return (
    <div className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.logoColumn}>
          <img src={logoRound} className={styles.logoImage} alt="Logo round" />
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.footerItem}>
              <div className={styles.footerItemRow}>
                <div className={styles.titleText}>Our People</div>
              </div>
              <div className={styles.footerItemRow}>
                <Link to="/about" className={styles.footerItemLink}>
                  <div className={styles.bodyText}>Our Story</div>
                </Link>
              </div>
              <div className={styles.footerItemRow}>
                <Link to="/careers" className={styles.footerItemLink}>
                  <div className={styles.bodyText}>Careers</div>
                </Link>
              </div>
              <div className={styles.footerItemRow}>
                <Link
                  to="/programs/early-learning"
                  className={styles.footerItemLink}
                >
                  <div className={styles.bodyText}>Programs</div>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.footerItem}>
              <div className={styles.footerItemRow}>
                <div className={styles.titleText}>Enquiries</div>
              </div>
              <div className={styles.footerItemRow}>
                <a
                  href="https://ccs-earlylearningonrossi.kinderm8.com.au/enquiry-form"
                  target="_blank"
                  className={styles.footerItemLink}
                  rel="noopener noreferrer"
                >
                  <div className={styles.bodyText}>General Enquiries</div>
                </a>
              </div>
              <div className={styles.footerItemRow}>
                <a
                  href="https://ccs-earlylearningonrossi.kinderm8.com.au/parent-tour-form"
                  target="_blank"
                  className={styles.footerItemLink}
                  rel="noopener noreferrer"
                >
                  <div className={styles.bodyText}>Parent Tour</div>
                </a>
              </div>
              <div className={styles.footerItemRow}>
                <a
                  href="https://ccs-earlylearningonrossi.kinderm8.com.au/waitlist-form"
                  target="_blank"
                  className={styles.footerItemLink}
                  rel="noopener noreferrer"
                >
                  <div className={styles.bodyText}>Join our Waitlist</div>
                </a>
              </div>
              <div className={styles.footerItemRow}>
                <a
                  href="https://ccs-earlylearningonrossi.kinderm8.com.au/enrolment-form"
                  target="_blank"
                  className={styles.footerItemLink}
                  rel="noopener noreferrer"
                >
                  <div className={styles.bodyText}>Enrolment Form</div>
                </a>
              </div>
            </div>
          </div>
          <div className={`${styles.column} ${styles.contactColumn}`}>
            <div className={styles.footerItem}>
              <div
                className={`${styles.footerItemRow} ${styles.contactColumn}`}
              >
                <div className={styles.titleText}>Contact Us</div>
              </div>
              <div className={styles.footerItemRow}>
                <div className={styles.contactItem}>
                  <a
                    href={`mailto:${email}?subject=Website Enquiry`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.footerItemLink}
                  >
                    <FaIcons.FaRegEnvelope className={styles.contactIcon} />
                    <div className={styles.bodyText}>
                      {`${email?.replace('@', '@\u200B') || ''}`}
                    </div>
                  </a>
                </div>
              </div>
              <div className={styles.footerItemRow}>
                <div className={styles.contactItem}>
                  <a
                    href={addressGoogleMapsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.footerItemLink}
                  >
                    <FaIcons.FaMapPin className={styles.contactIcon} />
                    <div className={styles.bodyText}>{address}</div>
                  </a>
                </div>
              </div>
              <div className={styles.footerItemRow}>
                <div className={styles.contactItem}>
                  <a href={`tel:${phone}`} className={styles.footerItemLink}>
                    <FaIcons.FaPhoneAlt className={styles.contactIcon} />
                    <div className={styles.bodyText}>{phone}</div>
                  </a>
                </div>
              </div>
              <div className={styles.contactItem} style={{ marginTop: 15 }}>
                <div
                  className={styles.titleText}
                  style={{ marginLeft: 40, fontSize: '16px' }}
                >
                  {openingHours}
                </div>
              </div>
              {openingHoursMessage && (
                <div className={styles.contactItem}>
                  <div
                    className={styles.bodyText}
                    style={{ marginLeft: 40, textAlign: 'left' }}
                  >
                    {openingHoursMessage}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.aocWrapper}>
          <div className={styles.row}>
            <div className={styles.column}>
              <div className={styles.titleText}>Acknowledgement Of Country</div>
            </div>
            <div className={styles.column}>
              <div className={styles.aocBodyWrapper}>
                <div className={styles.bodyText}>
                  Early Learning on Rossi acknowledges the Ngunnawal people, the
                  Traditional Custodians of the land on which we meet. We pay
                  our respects to Elders past, present, and emerging, and extend
                  our respect to all First Nations peoples joining us today.
                </div>
                <div className={styles.aocImagesWrapper}>
                  <img
                    src={flag1}
                    width={50}
                    height={37}
                    alt="Australian Aboriginal Flag"
                  />
                  <img
                    src={flag2}
                    width={50}
                    height={37}
                    alt="Torres Strait Islander Flag"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {socials && (
          <div className={styles.row}>
            <div className={styles.contactSocialIconsWrapper}>
              {socials?.map((item, index) => {
                const { icon, href, label } = item;
                const IconElement =
                  icon?.type === 'Fa' || icon?.name?.startsWith('Fa')
                    ? FaIcons[`${icon?.name}`]
                    : MdIcons[`${icon?.name}`];

                return (
                  IconElement && (
                    <a
                      key={`footer-element-${index}`}
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconElement className={styles.contactIcon} />
                    </a>
                  )
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className={styles.copyright}>
        <span>
          © Copyright {year} {siteName}. All Rights Reserved.
        </span>
      </div>
    </div>
  );
};

export default Footer;
